.slideshowWindow {
  width: inherit;
  align-self: center;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}

.slideImage {
  display: block;
  position: fixed;
  transition-duration: 0.95s;
  opacity: 0;

  padding: 10px 0px 0px 10px;
}

.slideImageActive {
  display: block;
  opacity: 1;
  transition-duration: 0.95s;

  padding: 10px 0px 0px 10px;
}

@media screen and (max-width: 600px), (max-height: 600px) {
  .slideshowWindow {
    height: inherit;
  }
}
