.website {
  width: inherit;
  height: inherit;
  overflow: hidden;
  object-fit: contain;
}

@media screen and (max-width: 600px), (max-height: 600px) {
  .website {
    height: inherit;
  }
}
