@import "../../../style/colors.scss";
@import "../../../style/defaults.scss";
@import "../../../style/mixins.scss";
@import "../../../style/sizes.scss";
@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

.selectField {
  position: relative;
  height: auto;
  color: $fontColor;
  padding-top: $spacing-s;
  margin-bottom: $spacing-m;
  width: 100%;

  .label {
    position: absolute;
    margin: 0;
    background: white;
    padding: 0 $spacing-s;
    &.default {
      bottom: $spacing-m;
    }
    &.thin {
      top: $spacing-s;
    }
    left: $spacing-s;
    z-index: 1;
    transition: transform 0.2s;
    transform-origin: top left;
    user-select: none;

    max-width: calc(100% - 4rem);
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;

    &.small {
      transform: translate(4px, -26px) scale(0.75);
      color: $primary;
      max-width: calc(100% + 4rem);
      &.focus {
        color: $primaryDark;
      }
    }
  }
  .arrow {
    height: $iconMediumSize;
    padding: 2px;
    cursor: pointer;
    &:hover {
      fill: $primary;
    }
  }

  .selectionInput {
    width: 100%;
    text-align: left;
    &.default {
      height: $defaultFieldHeight;
      padding: 0 $spacing-m;
    }
    &.thin {
      height: $thinFieldHeight;
      padding: 0 $spacing-m;
    }
    background: none;
    border: 1px solid $primary;
    border-radius: $borderRadius;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: inherit;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    &:read-only {
      cursor: pointer;
    }
    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
    &:hover:not(:disabled) {
      border-color: $primaryDark;
    }
    &:focus,
    &:active {
      outline-width: 1px;
      outline-color: $primaryDark;
    }
  }

  .fieldIcons {
    position: absolute;
    right: $spacing-l;
    &.default {
      top: $spacing-l;
    }
    &.thin {
      top: $spacing-s;
    }
    display: flex;
    align-items: center;
    > * {
      margin-left: $spacing-xs;
    }
    .removeBtn {
      margin: 0;
    }
  }

  .list {
    position: absolute;
    background: $white;
    border-radius: $borderRadius;
    z-index: 10;
    &.thin {
      width: 95%;
    }
    &.default {
      width: 100%;
    }
    max-height: 30rem;
    overflow-y: auto;
    @include boxShadow(2);

    &.positionAbove {
      transform: translateY(calc(-100% + (-7rem)));
    }
    .listItem {
      display: grid;
      grid-template-columns: minmax(0, max-content) 2rem;
      gap: $spacing-s;
      padding: 1.2rem $spacing-m;
      cursor: pointer;
      &.selected {
        color: $primary;
      }
      &.active,
      &:hover {
        background: $highLight;
      }
      &.disabled {
        cursor: not-allowed;
        color: transparentize($fontColor, 0.4);
      }
      .icon {
        color: $affordance;
      }
      p {
        margin: 0;
        line-height: 1.3;
        &:first-child:not(:only-of-type) {
          font-weight: $fontWeightMedium;
        }
      }

      &.noMatch {
        cursor: default;
        &.active,
        &:hover {
          background: white;
        }
      }
    }
  }
}
