@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/sizes.scss";
@import "../../../style/spacing.scss";

.shipmentsTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  th {
    border: 1px solid #ddd;
    padding: $spacing-s;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    text-align: center;
    background-color: #cfeaf1;
  }
}

.shipmentsRow {
  :hover {
    background-color: #ddd;
  }

  td {
    border: 1px solid #ddd;
    padding: $spacing-s;
  }
}
