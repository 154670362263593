@import "../../../style/mixins.scss";
@import "../../../style/spacing.scss";

.headerSlideshow {
  display: flex;
  justify-content: space-between;
  width: inherit;
  text-align: center;
  overflow: hidden;
  @include boxShadow(2);
}

.headerMyloc {
  padding: $spacing-xs;
  padding-left: $spacing-xs;
  height: inherit;
  object-fit: scale-down;
  flex-shrink: 1;
}

.headerText {
  font-size: 5rem;
  align-self: center;
  flex-shrink: 3;
  font-family: "Roboto";
}

.companyLogo {
  padding: $spacing-xs;
  padding-right: $spacing-xs;
  height: inherit;
  object-fit: scale-down;
  float: right;
  flex-shrink: 2;
  max-width: 336px;
}

.logoBox {
  height: inherit;
  min-width: 336px;
}

@media screen and (max-width: 600px), (max-height: 600px) {
  .headerSlideshow {
    display: none;
  }
}
