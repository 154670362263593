.backdiagonal {
  background-image: url("./backdiagonal_grey.png");
}
.bubbles {
  background-image: url("./bubbles_grey.png");
}
.crosses {
  background-image: url("./crosses_grey.png");
}
.dots {
  background-image: url("./dots_grey.png");
}
.grid {
  background-image: url("./grid_grey.png");
}
.horizontalzigzag {
  background-image: url("./horizontalzigzag_grey.png");
}
.horizontal {
  background-image: url("./horizontal_grey.png");
}
.rhombs {
  background-image: url("./rhombs_grey.png");
}
.verticalzigzag {
  background-image: url("./verticalzigzag_grey.png");
}
.vertical {
  background-image: url("./vertical_grey.png");
}
.blank {
  background-image: none;
}
