@import "../../style/typography.scss";

.container {
  display: table-row;
  align-items: center;
  justify-content: center;
  align-self: center;
  object-fit: contain;
}

.connectionProblems {
  text-align: center;
  font-size: $fontSizeLarge;
}
