@import "../../../style/z-index.scss";
@import "../../../style/typography.scss";
@import "../../../style/colors.scss";
@import "../../../style/spacing.scss";

$titleHeight: 60px;

.drawer {
  z-index: $drawer;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: 0.5s;
  overflow: auto;

  &.closed {
    width: 0;
    left: 100%;
  }
}

.title {
  border-bottom: 1px solid $darkWhite;
  padding: 0 $spacing-s;
  position: fixed;
  width: 100%;
  background-color: inherit;
  z-index: inherit;
  height: $titleHeight;

  h2 {
    @extend %sectionTitle;
    padding: $spacing-m $spacing-xl 0;
  }
}

.content {
  overflow: scroll;
  &.withTitle {
    margin-top: $titleHeight;
  }
}
