@import "../../../style/colors.scss";

.marker {
  display: block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border: 2px solid #f5f5f5;
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 0 3px gray;
  font-size: 20px;
  font-weight: bold;
}

.marker:hover {
  background: #777555;
}

.text {
  color: $red2;
  background-color: rgba(255, 255, 255, 0.5);
  font-weight: bold;
  font-size: 14px;
}
