.input {
  width: 15rem;
  top: 2.1rem;
}

.container {
  display: grid;
  grid-template-columns: 55% 45%;
  grid-template-rows: auto auto 90vh;
  grid-template-areas:
    "picker filter"
    "map filter"
    "map passages";
}

.map {
  grid-area: map;
}
.filter {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  grid-area: filter;
}

.passages {
  grid-area: passages;
}

.selectionLabel {
  text-align: center;
}
