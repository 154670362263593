@import "../../../style/spacing.scss";

.calendarEventNormal {
  background-repeat: repeat;
  height: 100%;
  overflow: hidden;
}

.iconText {
  align-items: center;
  text-align: center;
  display: flex;
  width: max-content;
  gap: $spacing-xs;
}
