@import "../../../style/sizes.scss";
@import "../../../style/spacing.scss";

.calendarEventSmall {
  background-repeat: repeat;
  height: 100%;
  overflow: hidden;
}

.iconText {
  align-items: center;
  text-align: center;
  display: flex;
  width: max-content;
  gap: $spacing-xs;
  border-radius: $borderRadius;
  padding-right: $spacing-xs;
}
